// @file Commands available on
import { copyToClipboard } from '@@/bits/clipboard'
import { navigateTo } from '@@/bits/location'
import { getVuexStore } from '@@/bits/pinia'
import { buildSlideshowLink } from '@@/bits/slideshow'
import { CommandLevel } from '@@/enums'
import { useSurfaceStore } from '@@/pinia/surface'
import { useSurfaceActivityPanelStore } from '@@/pinia/surface_activity_panel'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import { useSurfaceSectionsStore } from '@@/pinia/surface_sections'
import type { Command } from '@@/types'
import { PageType } from '@@/types/slideshow'
import type { RootState } from '@@/vuexstore/surface/types'

interface CommandsComposable {
  commands: Command[]
}

export function useSurfaceCommands(): CommandsComposable {
  const commands: Command[] = [
    /*
     * COMMON ACTIONS
     */
    {
      name: 'Create a post',
      description: 'Write a post with subject, body and attachment',
      icon: 'plus_thick',
      level: CommandLevel.Padlet,
      action: () => {
        useSurfacePostsStore().startNewPost({})
      },
    },
    {
      name: 'Create a section',
      description: 'Create a new section in the current padlet',
      icon: 'section',
      level: CommandLevel.Padlet,
      action: () => {
        const createSectionOptions = {
          promptRenameSection: true,
        }
        void useSurfaceSectionsStore().createSectionWithDefaultName(createSectionOptions)
      },
    },
    {
      name: 'Add to bookmarks',
      description: 'Add this padlet to bookmarks',
      icon: 'star_filled',
      level: CommandLevel.Library,
      action: () => {
        void getVuexStore<RootState>()?.dispatch('bookmarkWallAndNotify')
      },
    },
    {
      name: 'Copy link to padlet',
      description: 'Copy the link to this padlet',
      icon: 'link_filled',
      level: CommandLevel.Padlet,
      action: () => {
        void copyToClipboard(useSurfaceStore().links.show)
      },
    },
    {
      name: 'Copy link to slideshow',
      description: 'Copy the link to the slideshow for this padlet',
      icon: 'link_filled',
      level: CommandLevel.Padlet,
      action: () => {
        void copyToClipboard(buildSlideshowLink(useSurfaceStore().links.show, PageType.Cover))
      },
    },
    /*
     * NAV BAR BUTTON ACTIONS
     */
    {
      name: 'Open Remake Panel',
      description: 'Open the remake panel',
      icon: 'arrow_2_rectangular_clockwise_thick',
      level: CommandLevel.Padlet,
      action: () => {
        void getVuexStore<RootState>()?.dispatch('showRemakePanelOld')
      },
    },
    {
      name: 'Open Activity Panel',
      description: 'Open the activity panel',
      icon: 'bell_filled',
      level: CommandLevel.Padlet,
      action: () => {
        useSurfaceActivityPanelStore().showActivityPanel()
      },
    },
    {
      name: 'Open Slideshow in a new tab',
      description: 'Open the slideshow in a new tab',
      icon: 'play',
      level: CommandLevel.Padlet,
      action: () => {
        navigateTo(buildSlideshowLink(useSurfaceStore().links.show, PageType.Cover), { target: '_blank' })
      },
    },
    {
      name: 'Open Settings Panel',
      description: 'Open the settings panel',
      icon: 'gear_filled',
      level: CommandLevel.Padlet,
      action: () => {
        void getVuexStore<RootState>()?.dispatch('settings/toggleSettingsPanel')
      },
    },
  ]

  return { commands }
}
